<template>
  <div class="index">
    <div class="nav1">
      <div class="tit1">用户管理</div>
    </div>
    <div class="nav2">
      <div class="myForm">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="ID搜索：">
            <el-input size="small" v-model="formInline.user_id"></el-input>
          </el-form-item>
          <el-form-item label="公司名称：">
            <el-input size="small" v-model="formInline.com_name"></el-input>
          </el-form-item>
          <el-form-item label="岗位名称：">
            <el-input size="small" v-model="formInline.postname"></el-input>
          </el-form-item>
          <el-form-item label="排序：">
            <el-select size="small" v-model="formInline.sort" placeholder="请选择">
              <el-option label="默认" :value="0"></el-option>
              <el-option label="最近登录" v-show="false" :value="1"></el-option>
              <el-option label="测试匹配度次数" v-show="false" :value="2"></el-option>
              <el-option label="微简历" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="tit1">
        <el-button @click="toAddShop" size="small" type="primary" icon="el-icon-plus">添加商品</el-button>
      </div>-->
      <div class="myTable">
        <vxe-table :data="tableData">
          <!-- <vxe-table-column type="expand" width="30" :fixed="null">
            <template #content="{ row }">
              <template>
                <div class="xiala" v-if='row.realname'>
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <div class="item">姓名：{{row.realname.real_name}}</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="item">身份证号：{{row.realname.id_number}}</div>
                    </el-col>
                  </el-row>
                  <div style="margin-top: 16px"></div>
                  <el-row :gutter="20">
                    <el-col :span="18">
                      <div class="imgDiv">
                        <el-image
                          style="width:260px;height:150px;margin-right:8px;"
                          :src="row.realname.front_img"
                          :preview-src-list="row.myImg_paths"
                          fit="fill"
                        >
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                        <el-image
                          style="width:260px;height:150px;margin-right:8px;"
                          :src="row.realname.back_img"
                          :preview-src-list="row.myImg_paths"
                          fit="fill"
                        >
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="xiala" v-else>
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <div class="item">还未实名认证</div>
                    </el-col>
                  </el-row>
                </div>
              </template>
            </template>
          </vxe-table-column> -->
          <vxe-table-column field="id" title="ID"></vxe-table-column>
          <vxe-table-column field="nickname" title="昵称"></vxe-table-column>
          <vxe-table-column field="avatar" title="头像">
            <template slot-scope="scope">
              <el-image :src="scope.row.userface" :preview-src-list="[scope.row.userface]" fit="fill"
                style="width: 40px; height: 40px">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </vxe-table-column>
<!--          <vxe-table-column field="degree_num" title="测匹配度次数" v-if="false"></vxe-table-column>-->
          <vxe-table-column field="weijianli" title="微简历">
            <template slot-scope="scope">
              <el-button v-if="scope.row.weijianli == 1" size="small" @click="chakanWeijianli(scope.row.id)" type="text">查看</el-button>
            </template>
          </vxe-table-column>
          <vxe-table-column field="last_logintime" title="最后登录时间"></vxe-table-column>
          <!-- <vxe-table-column field="myStatus" width="120" title="状态(是否通过)">
            <template slot-scope="scope">
              <el-switch @change="changeKG(scope.row)" v-model="scope.row.myStatus"></el-switch>
            </template>
          </vxe-table-column>-->
          <vxe-table-column title="操作状态" width="120">
            <template slot-scope="scope">
              <div class="flex">
                <el-button v-if="scope.row.is_status == 1" size="small" @click="lahei(scope.row,2)" type="text">拉黑</el-button>
                <el-button v-if="scope.row.is_status == 2" size="small" @click="lahei(scope.row,1)" type="text">恢复</el-button>
                <!-- <el-button size="small" :disabled='!scope.row.realname || scope.row.realname.status != 0' @click="jujue(scope.row)" type="text">拒绝</el-button> -->
                <!-- <el-button size="small" @click="toEditShop(scope.row)" type="text">查看评论</el-button> -->
                <!-- <el-button size="small" @click="toDelShop(scope.row)" type="text">删除</el-button> -->
              </div>
            </template>
          </vxe-table-column>
        </vxe-table>
        <el-dialog title="微简历" :visible.sync="dhaddDialogVisible" width="500px" :before-close="dhaddHandleClose">
          <div class="myAddForm">
            <el-descriptions size="small" border :column="1">
              <el-descriptions-item label="第一学历">{{user_candidate_resume.first_degree}}</el-descriptions-item>
              <el-descriptions-item label="最高学历">{{user_candidate_resume.highest_education}}</el-descriptions-item>
              <el-descriptions-item label="年龄">{{user_candidate_resume.basic.age}}</el-descriptions-item>
              <el-descriptions-item label="第一段工作经历" v-show="user_candidate_resume.work.length>=1"></el-descriptions-item>
              <el-descriptions-item label="公司" v-show="user_candidate_resume.work.length>=1">{{user_candidate_resume.work.length>=1?user_candidate_resume.work[0].com_name:""}}</el-descriptions-item>
              <el-descriptions-item label="岗位" v-show="user_candidate_resume.work.length>=1">{{user_candidate_resume.work.length>=1?user_candidate_resume.work[0].postname:""}}</el-descriptions-item>
              <el-descriptions-item label="工作年" v-show="user_candidate_resume.work.length>=1">{{user_candidate_resume.work.length>=1?user_candidate_resume.work[0].work_year:""}}</el-descriptions-item>
              <el-descriptions-item label="工作月" v-show="user_candidate_resume.work.length>=1">{{user_candidate_resume.work.length>=1?user_candidate_resume.work[0].work_month:""}}</el-descriptions-item>
              <el-descriptions-item label="第二段工作经历" v-show="user_candidate_resume.work.length>=2"></el-descriptions-item>
              <el-descriptions-item label="公司" v-show="user_candidate_resume.work.length>=2">{{user_candidate_resume.work.length>=2?user_candidate_resume.work[1].com_name:""}}</el-descriptions-item>
              <el-descriptions-item label="岗位" v-show="user_candidate_resume.work.length>=2">{{user_candidate_resume.work.length>=2?user_candidate_resume.work[1].postname:""}}</el-descriptions-item>
              <el-descriptions-item label="工作年" v-show="user_candidate_resume.work.length>=2">{{user_candidate_resume.work.length>=2?user_candidate_resume.work[1].work_year:""}}</el-descriptions-item>
              <el-descriptions-item label="工作月" v-show="user_candidate_resume.work.length>=2">{{user_candidate_resume.work.length>=2?user_candidate_resume.work[1].work_month:""}}</el-descriptions-item>
              <el-descriptions-item label="第三段工作经历" v-show="user_candidate_resume.work.length>=3"></el-descriptions-item>
              <el-descriptions-item label="公司" v-show="user_candidate_resume.work.length>=3">{{user_candidate_resume.work.length>=3?user_candidate_resume.work[2].com_name:""}}</el-descriptions-item>
              <el-descriptions-item label="岗位" v-show="user_candidate_resume.work.length>=3">{{user_candidate_resume.work.length>=3?user_candidate_resume.work[2].postname:""}}</el-descriptions-item>
              <el-descriptions-item label="工作年" v-show="user_candidate_resume.work.length>=3">{{user_candidate_resume.work.length>=3?user_candidate_resume.work[2].work_year:""}}</el-descriptions-item>
              <el-descriptions-item label="工作月" v-show="user_candidate_resume.work.length>=3">{{user_candidate_resume.work.length>=3?user_candidate_resume.work[2].work_month:""}}</el-descriptions-item>
              <el-descriptions-item label="第四段工作经历" v-show="user_candidate_resume.work.length>=4"></el-descriptions-item>
              <el-descriptions-item label="公司" v-show="user_candidate_resume.work.length>=4">{{user_candidate_resume.work.length>=4?user_candidate_resume.work[3].com_name:""}}</el-descriptions-item>
              <el-descriptions-item label="岗位" v-show="user_candidate_resume.work.length>=4">{{user_candidate_resume.work.length>=4?user_candidate_resume.work[3].postname:""}}</el-descriptions-item>
              <el-descriptions-item label="工作年" v-show="user_candidate_resume.work.length>=4">{{user_candidate_resume.work.length>=4?user_candidate_resume.work[3].work_year:""}}</el-descriptions-item>
              <el-descriptions-item label="工作月" v-show="user_candidate_resume.work.length>=4">{{user_candidate_resume.work.length>=4?user_candidate_resume.work[3].work_month:""}}</el-descriptions-item>

            </el-descriptions>
          </div>
        </el-dialog>
        <el-pagination class="fenye" @size-change="this.handleSizeChange" @current-change="this.handleCurrentChange"
          :current-page="this.jishiShougouPage" :page-size="10" :page-sizes="[10, 15, 20, 30]"
          layout="total,sizes, prev, pager, next, jumper" :total="this.total"></el-pagination>
      </div>
    </div>
    <!-- 查看评论 -->
    <el-dialog title="查看评论" :visible.sync="seePinlunDialogVisible" width="1000px" :before-close="seePinlunHandleClose">
      <!-- <div class="myForm">
        <el-form :inline="true" :model="searchPinlunForm" class="demo-form-inline">
          <el-form-item show-overflow="title" size="small" label="关键词搜索：">
            <el-input v-model="searchPinlunForm.keyword" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="searchPinlunOnSubmit">查询</el-button>
            <el-button size="small" @click="searchPinlunOnResult">重置</el-button>
          </el-form-item>
        </el-form>
      </div>-->
      <div class="myTable">
        <vxe-table :tree-config="{
          children: 'comment_reply_list',
        }" :data="pinlunList">
          <vxe-table-column field="id" width="80" title="ID"></vxe-table-column>
          <vxe-table-column tree-node field="myNickname" title="评论者"></vxe-table-column>
          <vxe-table-column field="avatar_url" idth="100" title="头像">
            <template slot-scope="scope">
              <el-image :preview-src-list="[scope.row.avatar_url]" :src="scope.row.avatar_url" fit="fill"
                style="width: 40px; height: 40px">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column field="content" min-width="200" title="评论内容"></vxe-table-column>
          <vxe-table-column field="zan_count" width="80" title="获赞"></vxe-table-column>
          <vxe-table-column field="add_time" title="评论时间"></vxe-table-column>
        </vxe-table>
        <el-pagination class="fenye" @current-change="this.handleCurrentChange1"
          :current-page="this.biaobaiqiangPinglunPage" :page-size="10" layout="total, prev, pager, next, jumper"
          :total="this.pinlunCount"></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState([
      "jishiShougouPage",
      "jishiShougouPageSize",
      "biaobaiqiangPinglunPage",
      "biaobaiqiangPinglunPageSize"
    ])
  },
  watch: {
    jishiShougouPage: function (page) {
      this.$store.commit("jishiShougouPage", page);
      this.getData();
    },
    jishiShougouPageSize: function (pageSize) {
      this.$store.commit("jishiShougouPageSize", pageSize);
      this.getData();
    },
    biaobaiqiangPinglunPage: function (page) {
      this.$store.commit("biaobaiqiangPinglunPage", page);
      this.getPinlunData();
    },
    biaobaiqiangPinglunPageSize: function (pageSize) {
      this.$store.commit("biaobaiqiangPinglunPageSize", pageSize);
      this.getPinlunData();
    }
  },
  data() {
    return {
      searchPinlunForm: {
        keyword: ""
      },
      seePinlunDialogVisible: false,
      pinlunCount: 0,
      pinlunId: "",
      pinlunList: [],
      //
      activeName: "1",
      formInline: {
        user_id: "",
        sort: 0,
        com_name:"",
        postname:""
      },
      options: [],
      tableData: [],
      total: 0,
      dhaddDialogVisible: false,
      user_candidate_resume:{
        first_degree:"",
        highest_education:"",
        basic:{
          age:0
        },
        work:[
          {
            com_name:"",
            postname:"",
            work_year:"",
          },{
            com_name:"",
            postname:"",
            work_year:"",
          },{
            com_name:"",
            postname:"",
            work_year:"",
          },{
            com_name:"",
            postname:"",
            work_year:"",
          }
        ]
      }
    };
  },
  created() {
    this.$store.commit("biaobaiqiangPinglunPage", 1);
    this.getData();
  },
  methods: {
    dhaddHandleClose() {
      this.dhaddDialogVisible = false;
    },
    async getData() {
      const res = await this.$api.memberlist({
        pagesize: this.jishiShougouPageSize,
        page: this.jishiShougouPage,
        user_id: this.formInline.user_id,
        sort: this.formInline.sort,
        com_name:this.formInline.com_name,
        postname:this.formInline.postname
      });
      console.log(res.list);
      this.total = res.total;
      this.tableData = res.list;
    },
    async lahei(row,i) {
      const res = await this.$api.del_member({
        id: row.id,
        is_status:i
      })
      if (res.result == 1) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
      }
    },
    async tongguo(row) {
      const res = await this.$api.user_check_realname({
        id: row.realname.id,
        status: 1
      })
      console.log(res)
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: "success"
        });
        this.getData();
      }
    },
    async jujue(row) {
      const res = await this.$api.user_check_realname({
        id: row.realname.id,
        status: -1
      })
      console.log(res)
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: "success"
        });
        this.getData();
      }
    },
    async getPinlunData() {
      const res = await this.$api.comment_list({
        type: "jishi",
        id: this.pinlunId,
        page: this.biaobaiqiangPinglunPage,
        pagesize: this.biaobaiqiangPinglunPageSize
      });
      this.pinlunCount = res.data.total;
      this.pinlunList = res.data.data;
      this.pinlunList.forEach(ele => {
        ele.myNickname =
          ele.nickname == "" || !ele.nickname ? "匿名用户" : ele.nickname;
        if (ele.comment_reply_list) {
          ele.comment_reply_list.forEach(item => {
            item.myNickname =
              item.nickname == "" || !item.nickname
                ? "匿名用户"
                : item.nickname;
          });
        }
      });
    },
    toSeeXiangqin(row) {
      this.$store.commit("biaobaiqiangPinglunPage", 1);
      this.pinlunId = row.id;
      this.getPinlunData();
      this.seePinlunDialogVisible = true;
    },
    handleCurrentChange1(val) {
      console.log(`当前页: ${val}`);
      this.$store.commit("biaobaiqiangPinglunPage", val);
    },
    searchPinlunOnSubmit() { },
    searchPinlunOnResult() {
      this.searchPinlunForm.keyword = "";
      this.getPinlunData();
    },
    seePinlunHandleClose() {
      this.seePinlunDialogVisible = false;
    },
    // 开关（上架/下架）
    async changeKG(row) {
      console.log(row);
      const res = await this.$api.update_status({
        status: row.myStatus == true ? "0" : "1",
        id: row.id,
        type: "jishi"
      });
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
      }
    },
    toEditShop(row) {
      console.log(row);
      this.$store.commit("shopObj", row);
      this.$router.push({ name: "Tianjiashangping" });
    },
    async toDelShop(row) {
      console.log(row);
      const res = await this.$api.deleteItems(row.id);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
      }
    },
    tabsHandleClick(tab) {
      console.log(tab.index);
      this.$store.commit("tabIndex", (Number(tab.index) + 1).toString());
      this.formInline.category_id = "";
      this.formInline.name = "";
    },
    onSubmit() {
      console.log("submit!");
      this.$store.commit("jishiShougouPage", 1);
      this.getData();
    },
    toAddShop() {
      this.$store.commit("shopObj", null);
      this.$router.push({ name: "Tianjiashangping" });
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$store.commit("jishiShougouPageSize", val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$store.commit("jishiShougouPage", val);
    },
    async chakanWeijianli(user_id){
      console.log(user_id)
      const res=await this.$api.user_candidateview({
        user_id:user_id
      })
      this.user_candidate_resume=res.user_candidate_resume
      this.dhaddDialogVisible=true;
    }
  }
};
</script>

<style lang="scss" scoped>
.index {}

.shopxx {
  display: flex;
  align-items: center;
  margin: 4px 0;

  .shopImg {
    width: 40px;
    height: 40px;
    margin-right: 6px;
  }

  .txt {
    font-size: 12px;
    line-height: 16px;
  }
}

.nav1 {
  margin: 0 -18px;
  background-color: #fff;
  padding: 20px 32px 0 40px;

  .tit1 {
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 20px;
  }

  .tit2 {
    margin-top: 10px;

    ::v-deep  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px;
    }

    ::v-deep  .el-tabs__header {
      margin: 0 0 1px;
    }
  }
}

.nav2 {
  margin: 18px 0;
  background: #fff;
  border-radius: 6px;
  padding: 24px;

  .myForm {
    ::v-deep  .el-form-item__label {
      font-size: 12px;
    }

    ::v-deep  .el-form-item {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  .tit1 {
    margin-top: 10px;
  }

  .myTable {
    margin-top: 10px;

    .xiala {
      padding: 10px 20px;

      .item {
        font-size: 12px;
      }
    }

    .flex {
      display: flex;
      align-items: center;
    }

    .fenye {
      margin-top: 10px;
    }

    ::v-deep  .vxe-table--render-default .vxe-body--column {
      line-height: 14px;
      vertical-align: middle;
    }

    ::v-deep  .vxe-cell--label {
      font-size: 12px;
    }

    ::v-deep  .vxe-cell--title {
      font-size: 12px;
    }

    ::v-deep  .image-slot {
      width: 38px;
      height: 38px;
      border: 1px solid #ddd;
      line-height: 38px;
      text-align: center;
      border-radius: 4px;
    }
  }
}

.myTable {
  margin-top: 10px;

  .xiala {
    padding: 10px 20px;

    .item {
      font-size: 12px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .fenye {
    margin-top: 10px;
  }

  ::v-deep  .vxe-table--render-default .vxe-body--column {
    line-height: 14px;
    vertical-align: middle;
  }

  ::v-deep  .vxe-cell--label {
    font-size: 12px;
  }

  ::v-deep  .vxe-cell--title {
    font-size: 12px;
  }

  ::v-deep  .image-slot {
    width: 38px;
    height: 38px;
    border: 1px solid #ddd;
    line-height: 38px;
    text-align: center;
    border-radius: 4px;
  }
}

.myForm {
  ::v-deep  .el-form-item__label {
    font-size: 12px;
  }

  ::v-deep  .el-form-item {
    margin-right: 30px;
    margin-bottom: 0;
    vertical-align: middle;
  }
}
</style>
